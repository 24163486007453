export const link = `
  data {
    attributes {
      slug
    }
  }
`

export const image = `
  data {
    attributes {
      url
      width
      height
    }
  }
`

export const file = `
  data {
    attributes {
      url
      ext
    }
  }
`

export const attributes = `
  attributes {
    attrClass
    attrId
  }
`


export const newsPost = `
  updatedAt
  postTitle: title
  date
  slug
  previewImage {
    ${image}
  }
  news_category {
    data {
      attributes {
        postCategoryTitle: title
      }
    }
  }
`

export const office = `
  data {
    id
    attributes {
      title
      type
      city {
        data {
          id
          attributes {
            name
            region {
              data {
                id
                attributes {
                  name
                }
              }
            }
          }
        }
      }
      address
      phone
      email
      timeWork
      latitude
      longitude
    }
  }
`

export const QuerySeo = `
  metaTitle
  metaDescription
  metaImage {
    ${image}
  }
  metaSocial {
    title
    socialNetwork
    description
    image {
      ${image}
    }
  }
  keywords
  metaRobots
  structuredData
  metaViewport
  canonicalURL
`