import PropTypes from 'prop-types'

export default function Icon({ name, width = 16, height = 16, ...props }) {

  if (!name) {
    return null
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
    >
      <use xlinkHref={`/sprites.svg#${name}`}></use>
    </svg>
  )
}

Icon.propTypes = {
  name: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
}