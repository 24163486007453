'use client'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useTranslations } from 'next-intl'
import Icon from '@/app/components/Ui/Icon'
import Button from '@/app/components/Ui/Button'

import { usePagination, DOTS } from './usePagination'
import styles from './Pagination.module.scss'

export default function Pagination({
  onPageChange,
  onLoadMore,
  totalCount,
  siblingCount = 1,
  activePages = [],
  currentPage,
  pageSize,
  isLoading = false,
  className
}) {
  const t = useTranslations('pagination')

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  })

  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const onNext = (e) => {
    e.preventDefault()
    if (currentPage === lastPage) {
      return
    }
    onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    if (currentPage === 1) {
      return
    }
    onPageChange(currentPage - 1)
  }

  const handlerLoadMore = () => {
    onLoadMore(currentPage + 1)
  }

  let lastPage = paginationRange[paginationRange.length - 1]

  return (
    <div className={classnames(styles['pagination'], { [className]: className })}>
      {currentPage !== lastPage && (
        <Button
          isLoading={isLoading}
          onClick={handlerLoadMore}
          theme='bordered'
          className={styles['pagination_btn-load-more']}
        >
          {t('showMore')}
        </Button>
      )}
      <div className={classnames(styles['pagination_actions'], 'pagination_actions d-f ai-c jc-c')}>
        <div
          className={classnames(styles['pagination_btn'], 'd-f ai-c jc-c')}
          disabled={currentPage === 1}
          onClick={onPrevious}
          type='button'
        >
          <Icon
            name={'arrow-left'}
            width={25}
            height={25}
            viewBox="0 0 25 25"
          />
        </div>
        <ul className={classnames(styles['pagination_list'], 'd-f ai-c fw-w jc-c')}>
          {paginationRange.map((pageNumber, index) => {
            if (pageNumber === DOTS) {
              return (
                <li
                  key={index}
                  className={classnames(
                    styles['pagination_item'],
                    styles['b-dots'],
                    'd-f ai-c jc-c'
                  )}
                >
                  &#8230;
                </li>
              )
            }
            return (
              <li
                className={classnames(
                  styles['pagination_item'],
                  {
                    [styles['b-selected']]: pageNumber === currentPage || activePages.includes(pageNumber)
                  },
                  'd-f ai-c jc-c'
                )}
                onClick={() => onPageChange(pageNumber)}
                key={index}
              >
                {pageNumber}
              </li>
            )
          })}
        </ul>
        <div
          className={classnames(styles['pagination_btn'], 'd-f ai-c jc-c')}
          disabled={currentPage === lastPage}
          onClick={onNext}
          type='button'
        >
          <Icon
            name={'arrow-right'}
            width={25}
            height={25}
            viewBox="0 0 25 25"
          />
        </div>
      </div>
    </div>
  )
}

Pagination.propTypes = {
  onPageChange: PropTypes.func,
  onLoadMore: PropTypes.func,
  totalCount: PropTypes.number,
  siblingCount: PropTypes.number,
  currentPage: PropTypes.number,
  activePages: PropTypes.arrayOf(PropTypes.number),
  pageSize: PropTypes.number,
  isLoading: PropTypes.bool,
  className: PropTypes.string
}
