import { GraphQLClient } from 'graphql-request'
import { ADMIN_URL, WEBSITE_URL, REVALIDATE_TIME } from '@/app/constants/constants'
const client = new GraphQLClient(`${ADMIN_URL}/graphql`, {
  fetch,
  next: {
    revalidate: REVALIDATE_TIME
  }
})

export const graphQLRequest = async (query, variables = {}) => await client.request(query, variables)

export const clientGraphQLRequest = async (query, variables) => {
  if (!query || !variables) {
    throw new Error("'query', 'variables' not specified.");
  }
  const isServerSide = typeof window === 'undefined'
  const res = await fetch(isServerSide ? `${WEBSITE_URL}/api/clientGraphQL` : '/api/clientGraphQL', {
    method: 'POST',
    body: JSON.stringify({
      query,
      variables
    })
  })
  return await res.json()
}

export const clientFetchRequest = async ({
  method = 'GET',
  url,
  body = {}
}) => {
  const isServerSide = typeof window === 'undefined'
  const res = await fetch(isServerSide ? `${WEBSITE_URL}/api/clientFetch` : '/api/clientFetch', {
    method,
    body: JSON.stringify({
      url,
      ...body
    })
  })
  return await res.json()
}