'use client'
import { useTranslations } from 'next-intl'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Link from '@/app/components/Ui/Link'

import styles from './Breadcrumbs.module.scss'

export default function Breadcrumbs({ items }) {
  const t = useTranslations('breadcrumbs')
  return (
    <div className={classNames(styles['breadcrumbs'], 'breadcrumbs')}>
      <div
        className={classNames(
          styles['breadcrumbs_wrapper'],
          'wr d-f ai-c'
        )}
        itemScope
        itemType='http://schema.org/BreadcrumbList'
      >
        <div
          className={styles['breadcrumbs_item']}
          itemProp='itemListElement'
          itemScope
          itemType='http://schema.org/ListItem'
        >
          <Link
            href={'/'}
            className={styles['breadcrumbs_link']}
            itemProp='item'
          >
            <span itemProp='name'>{t('home')}</span>
          </Link>
          <meta itemProp='position' content='1' />
        </div>
        {Array.isArray(items) && items.length !== 0 && items.map((item, index) => (
          <div
            key={index}
            className={styles['breadcrumbs_item']}
            itemProp='itemListElement'
            itemScope
            itemType='http://schema.org/ListItem'
          >
            {items.length - 1 === index ? (
              <div
                className={classNames(styles['breadcrumbs_link'], styles['b-last'])}
                itemProp='name'
              >
                {item.name}
              </div>
            ) : (
              <Link
                href={item.link}
                className={styles['breadcrumbs_link']}
                itemProp='item'
              >
                <span itemProp='name'>{item.name}</span>
              </Link>
            )}
            <meta itemProp='position' content={index + 2} />
          </div>
        ))}
      </div>
    </div>
  )
}

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string
  }))
}