import classNames from 'classnames'
import PropTypes from 'prop-types'

export default function Button({ theme = 'default', extraOption = '', isLoading = false, children, className, ...props }) {
  return (
    <button
      className={classNames(
        'btn',
        `bg-${theme}`,
        {
          'b-loading': isLoading,
          'b-only-icon': extraOption === 'only-icon',
          'b-with-icon': extraOption === 'with-icon',
          [className]: className
        }
      )}
      disabled={isLoading}
      {...props}
    >
    {children}
    {isLoading && <span className='loader'></span>}
  </button>
  )
}

Button.propTypes = {
  theme: PropTypes.oneOf(['default', 'white', 'pink', 'bordered']),
  extraOption: PropTypes.oneOf(['only-icon', 'with-icon']),
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any
}